const stripSearchParamFromUrl = (url: string, searchParam: string) => {
  const strippedUrl = new URL(url);

  const searchParams = new URLSearchParams(strippedUrl.search);
  searchParams.delete(searchParam);
  strippedUrl.search = searchParams.toString();

  return strippedUrl.toString();
};

/**
 * Returns a URL with the disposition search parameter removed so that
 * the URL can be used to download content inline.
 *
 * @param {string} url URL to remove the search param from
 * @returns {string} URL with the disposition search param removed
 */
export const stripDispositionFromUrl = (url: string) =>
  stripSearchParamFromUrl(url, 'disposition');
