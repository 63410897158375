import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconName } from './icons';
import { VariantProps, cva } from 'class-variance-authority';

const iconStyles = cva(
  'material-symbols-rounded flex items-center justify-center select-none',
  {
    variants: {
      fill: {
        none: 'icon',
        filled: 'icon-filled',
      },
      size: {
        small: 'text-[16px]',
        medium: 'text-[18px]',
        large: 'text-[20px]',
        xl: 'text-[24px]',
      },
    },
    defaultVariants: {
      fill: 'none',
      size: 'xl',
    },
  },
);

export type IconProps = {
  name: IconName;
  label?: string;
  color?: string;
  filled?: boolean;
  animation?: string;
} & VariantProps<typeof iconStyles>;

/**
 * A wrapper for a Google Fonts Material Symbol (https://fonts.google.com/icons?icon.style=Rounded).
 *
 * By default, icons should not be filled as per the design system requirements. However, for cases where
 * when a filled icon is required (for example, filling a star icon for a rating), setting `filled` to true
 * will fill the icon.
 *
 * @example
 * ```tsx
 * <Icon name="call" color="text-content-positive" label="phone-number" />
 * ```
 */

export const Icon = forwardRef<HTMLSpanElement, IconProps>(
  (
    {
      name,
      size,
      color = 'text-content-default',
      filled = false,
      animation = '',
    },
    ref,
  ) => (
    <span
      className={twMerge(
        iconStyles({ size, fill: filled ? 'filled' : 'none' }),
        `${color} ${animation}`,
      )}
      aria-hidden="true"
      ref={ref}
    >
      {name}
    </span>
  ),
);

Icon.displayName = 'Icon';
