import { To } from 'react-router-dom';
import { Card } from '.';
import { Button, ButtonProps } from '../Button';
import { ButtonLink } from '../ButtonLink';
import { CardText } from '../CardText';
import { CardTitle } from '../CardTitle';

type SharedActionProps = {
  text: string;
  variant?: Extract<
    ButtonProps['variant'],
    'primary' | 'secondary' | 'tertiary'
  >;
};

type ActionButton = SharedActionProps & {
  to?: never;
  disabled?: boolean;
  onClick: () => void;
};

type ActionLink = SharedActionProps & {
  to: To;
  disabled?: never;
  onClick?: never;
};

type Action = ActionButton | ActionLink;

type WorkflowStepCardProps = {
  title: string;
  brandedTitle?: boolean;
  description?: string;
  action: Action;
};

export const WorkflowStepCard = ({
  brandedTitle,
  title,
  description,
  action,
}: WorkflowStepCardProps) => {
  const { text: actionText, variant = 'secondary', ...actionProps } = action;

  const actionElement = actionProps.to ? (
    <ButtonLink {...actionProps} variant={variant}>
      {actionText}
    </ButtonLink>
  ) : (
    <Button {...actionProps} variant={variant}>
      {actionText}
    </Button>
  );

  return (
    <Card className="space-y-6">
      <div className="space-y-4">
        <CardTitle as="h1" variant="2xl" branded={brandedTitle}>
          {title}
        </CardTitle>
        <CardText as="p" className="text-gray-500 whitespace-pre-line">
          {description}
        </CardText>
      </div>

      {actionElement}
    </Card>
  );
};
