import { CoreMessageFieldsFragment } from '@pm/graphql';
import Linkify from 'linkify-react';
import { trackAnalyticsEvent } from '@pm/analytics';
import MessageName from '../MessageName';
import { useMessageName } from '../../hooks/useMessageName';
import { Attachments } from '../Attachments';
import { DateTime } from '../DateTime';

type MessageProps = {
  message: CoreMessageFieldsFragment;
  userId: string;
  brand: string;
};

const Message = ({ message, userId, brand }: MessageProps) => {
  const senderName = useMessageName(brand, message.sender);

  return (
    <div className="mt-3 w-full shadow">
      <div className="bg-grey-50 flex items-center">
        <MessageName variant="sender">{senderName}</MessageName>
        <div className="text-grey-400 flex-1 p-4 text-right text-sm leading-8">
          <span className="ml-2">
            <DateTime value={message.createdAt} format="Pp" />
          </span>
        </div>
      </div>
      <div className="text-md bg-white px-4 py-6">
        <Linkify
          options={{
            target: '_blank',
            className: 'text-blue-500 underline hover:text-blue-600',
          }}
        >
          <p className="text-grey-600 whitespace-pre-wrap">{message.content}</p>
        </Linkify>
      </div>
      {'attachments' in message && (
        <Attachments
          attachments={message.attachments}
          handleAttachmentClick={() =>
            trackAnalyticsEvent('File downloaded', {
              userId,
              date: new Date().toLocaleString(),
              source: {
                category: 'Secure Message',
                messageId: message.id,
              },
            })
          }
        />
      )}
    </div>
  );
};

export { Message };
