import { ReactNode } from 'react';
import { AnalyticsPageTracking } from '../components/AnalyticsPageTracking/AnalyticsPageTracking';
import { LDUserLocation } from '../components/LDUserLocation';
import { ScrollToTop } from '../components/Scroll/ScrollToTop';
import { EnvironmentProvider } from '../providers/EnvironmentProvider';
import { LaunchDarklyProvider } from '../providers/LaunchDarklyProvider';
import { MainAppLayoutProvider } from '../providers/MainAppLayoutProvider';
import { Brand, FormattingContext } from '../types';
import { AuthProvider } from './auth';

type SharedAppProvidersProps = {
  children: ReactNode;
  launchDarklySDKKey: string;
  apiHost: string;
  environment: string;
  formattingContext: FormattingContext;
  brand: Brand;
  enableGoogleAnalyticsTracking?: boolean;
};

export const SharedAppProviders = ({
  children,
  launchDarklySDKKey,
  enableGoogleAnalyticsTracking,
  ...environmentProps
}: SharedAppProvidersProps) => {
  return (
    <EnvironmentProvider {...environmentProps}>
      <MainAppLayoutProvider>
        <AuthProvider>
          <LaunchDarklyProvider sdkKey={launchDarklySDKKey}>
            {children}
            <AnalyticsPageTracking
              enableGoogleAnalyticsTracking={enableGoogleAnalyticsTracking}
            />
            <LDUserLocation />
            <ScrollToTop />
          </LaunchDarklyProvider>
        </AuthProvider>
      </MainAppLayoutProvider>
    </EnvironmentProvider>
  );
};
