import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';
import {
  InlineMessageAction,
  InlineMessageActionType,
} from './InlineMessageAction';
import { InlineMessageIcon } from './InlineMessageIcon';
import { inlineMesasageStyles } from './styles';
import { InlineMessageVariantProps } from './types';
import { Icon } from '../Icon/Icon';

type InlineMessageProps = {
  instructions: string;
  title?: string;
  showLeadingIcon?: boolean;
  action?: InlineMessageActionType;
  canDismiss?: boolean;
} & InlineMessageVariantProps;

/**
 * Inline notifications show up in task flows to notify users of status changes,
 * or to provide additional information about specific content.
 * Inline notifications typically appear in close proximity to the primary content area.
 *
 * The action can either be a button a button link.
 *
 *
 * @example
 * <InlineMessage
 *  intent="default"
 *  title="Try google"
 *  instructions="To try google, click the link below"
 *  action={<Button>Learn more</Button>} />
 */
export const InlineMessage = ({
  title,
  instructions,
  showLeadingIcon = true,
  action,
  canDismiss = false,
  intent,
}: InlineMessageProps) => {
  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) {
    return null;
  }

  return (
    <div className={twMerge(inlineMesasageStyles({ intent }))}>
      {showLeadingIcon && (
        <div className="flex justify-center items-center self-start py-m">
          <InlineMessageIcon intent={intent} />
        </div>
      )}
      <div
        className={twMerge(
          'flex flex-col flex-auto gap-xxs self-center mt-m',
          action ? 'mb-xxs' : 'mb-m',
        )}
      >
        <div className="pl-xs">
          {title && (
            <Text as="h3" size="headingXS">
              {title}
            </Text>
          )}
          <Text size="bodyL">{instructions}</Text>
        </div>
        {action && <InlineMessageAction>{action}</InlineMessageAction>}
      </div>
      {canDismiss && (
        <div className="my-xxs">
          <IconButton
            aria-label="Dismiss message"
            intent="ghost"
            size="large"
            onClick={() => setIsDismissed(true)}
          >
            <Icon name="close" size="large" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

InlineMessage.displayName = 'InlineMessage';
