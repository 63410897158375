import { useApolloClient } from '@apollo/client/index.js';
import {
  useLoginMutation,
  useRecoverPasswordMutation,
  useResetPasswordMutation,
  useSignupPatientMutation,
} from '@pm/graphql';
import { TokenResponse } from '@react-oauth/google';
import { isPast, parse } from 'date-fns';
import { FunctionComponent, PropsWithChildren, useMemo, useState } from 'react';
import { AuthContext } from './context';
import { useEnvironment } from '../../hooks/env';

const storeToken = (token: string) => {
  // save our jwt token into session storage in case the page is refreshed
  localStorage.setItem('jwt', token);
};

export const parseJwt = (jwt: string) => {
  const base64Payload = jwt.split('.')[1];
  const payload: { sub?: number; exp?: number } = JSON.parse(
    atob(base64Payload),
  );

  return payload;
};

export const AuthProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const client = useApolloClient();
  const { googleAuthUrl } = useEnvironment();
  const [jwtToken, setJwtToken] = useState<string>(() => {
    const rawToken = localStorage.getItem('jwt');

    // if we don't have a token bail out
    if (!rawToken) {
      return '';
    }
    const parsedToken = parseJwt(rawToken);

    if (!parsedToken.exp) {
      localStorage.removeItem('jwt');
      return '';
    }

    // see if it's expired
    if (isPast(parse(parsedToken.exp.toString(), 't', new Date()))) {
      localStorage.removeItem('jwt');
      return '';
    }

    // our token is valid
    return rawToken;
  });
  const [error, setError] = useState(false);

  const userId = useMemo(() => {
    if (!jwtToken) {
      return undefined;
    }

    const parsedToken = parseJwt(jwtToken);

    if (!parsedToken.sub) {
      return undefined;
    }

    return parsedToken.sub.toString();
  }, [jwtToken]);

  const useLogin = () =>
    useLoginMutation({
      onCompleted: ({ login }) => {
        if (!login) {
          return;
        }

        const { jwt, errors } = login;

        if (errors.length) {
          return setError(true);
        }
        if (jwt) {
          setJwtToken(jwt);
          storeToken(jwt);
        }
      },
      // bug: some errors aren't being caught inside onError using mutations.
      // network errors are being caught in onError.
      // https://github.com/apollographql/apollo-client/issues/5708
      onError: (error) => {
        setError(true);
        throw error;
      },
    });

  const providerLogin = async (oauth_response: TokenResponse) => {
    const form = new FormData();
    form.append('access_token', oauth_response.access_token);
    const response = await fetch(googleAuthUrl, { method: 'POST', body: form });

    if (response.ok) {
      const jsonValue = await response.json();

      setJwtToken(jsonValue.jwt);
      storeToken(jsonValue.jwt);
    } else {
      setError(true);
    }
  };

  const useSignup = (login = true) =>
    useSignupPatientMutation({
      onCompleted: ({ signupPatient }) => {
        if (!signupPatient) {
          return;
        }
        const { jwt, patient, errors } = signupPatient;

        if (errors?.length) {
          setError(true);
          throw errors[0];
        }
        if (login && jwt) {
          setJwtToken(jwt);
          storeToken(jwt);
        }
        return patient;
      },
      // bug: some errors aren't being caught inside onError using mutations.
      // network errors are being caught in onError.
      // https://github.com/apollographql/apollo-client/issues/5708
      onError: (error) => {
        setError(true);
        throw error;
      },
    });

  const logout = () => {
    setJwtToken('');
    localStorage.removeItem('jwt');
    sessionStorage.clear();
    client.clearStore();
  };

  const useResetPassword = () =>
    useResetPasswordMutation({
      onCompleted: ({ resetPassword }) => {
        if (!resetPassword) {
          return;
        }

        const { errors } = resetPassword;

        if (errors.length) {
          setError(true);
        }
      },
    });

  const useRecoverPassword = () =>
    useRecoverPasswordMutation({
      onCompleted: ({ recoverPassword }) => {
        if (!recoverPassword) {
          return;
        }

        const { errors } = recoverPassword;

        if (errors.length) {
          setError(true);
        }
      },
    });

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!jwtToken,
        error,
        setError,
        providerLogin,
        logout,
        userId,
        useLogin,
        useSignup,
        useResetPassword,
        useRecoverPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
